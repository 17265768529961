
export enum PatternCode {
    alphabet_number = 'alphabet_number',
    chinese = 'chinese',
    postcode_ca = 'postcode_ca',
    postcode_us = 'postcode_us',
    postcode_cn = 'postcode_cn',
    phone_ca = 'phone_ca',
    phone_us = 'phone_us',
    phone_cn = 'phone_cn',
    phone_be = 'phone_be',
    phone_uni = 'phone_uni',
    item_name_jiayun = 'item_name_jiayun',
    item_name_uni = 'item_name_uni',
    email = 'email',
    password = 'password',
    no_numeric = 'no_numeric'
}

export type Pattern = {
    pattern: string;
    label: string;
    code: PatternCode;
};
