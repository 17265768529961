<ng-container *ngIf="data">
  <div class="flex flex-row justify-start space-x-5">
    <div class="font-semibold capitalize">
      {{ data.date }}
    </div>
    <div>
      <ng-container *ngIf="data.isOpen; else outOfService">
        {{ data | scheduleTimetable }}
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #outOfService>
  <span class="text-primary">
    {{ "service.status.out_of_service" | translate }}
  </span>
</ng-template>
