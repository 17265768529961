import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { Livemode } from "@app/common/interfaces";

@Component({
  selector: "shop-service",
  templateUrl: "./shop-service.component.html",
  styleUrls: ["./shop-service.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopServiceComponent implements OnInit {
  @Input() pickup: Livemode = 0;
  @Input() delivery: Livemode = 0;
  @Input() eatIn: Livemode = 0;

  constructor() {}

  ngOnInit(): void {}
}
