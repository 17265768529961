import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  inject,
  DestroyRef,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { tap } from "rxjs";
import { NotificationApiService } from "@app/api/notification";
import { OrderApiService } from "@app/api/order";
import { ShopApiService } from "@app/api/shop";
import { IShop } from "@app/api/shop/interfaces";
import { TrackByIndexFn } from "@app/common/functions";

@Component({
  selector: "shop-selector",
  templateUrl: "./shop-selector.component.html",
  styleUrls: ["./shop-selector.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopSelectorComponent implements OnInit {
  @Input() showLogo = false;
  @Input() centered = false;
  @Input() autoSelect = false;
  @Input() isCartPage = false;
  @Input() syncList = false;
  @Input() event = new EventEmitter<boolean>();
  list$ = signal<IShop[]>([]);
  shop: IShop | null = null;
  loading$ = this.shopService.loading$;
  trackByIndex = TrackByIndexFn;

  private destroyRef = inject(DestroyRef);
  constructor(
    private shopService: ShopApiService,
    private orderService: OrderApiService,
    private noticeService: NotificationApiService
  ) {}

  ngOnInit(): void {
    // if (this.syncList) {
    this.sync();
    // }
  }

  close(state: boolean) {
    this.event.emit(state);
  }

  select(shop: IShop) {
    this.loading$.set(true);
    this.orderService
      .updatePresetServiceShop({
        shopId: shop.id,
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loading$.set(false))
      )
      .subscribe({
        next: (res) => {
          const { error, success } = res;
          if (error) {
            this.noticeService.errorMessage(error);
          }

          if (success) {
            this.shopService.saveShop(shop);
            this.shopService.shopChange.emit(this.isCartPage);
            this.close(true);
          }
        },
      });
  }

  private sync(): void {
    this.shopService
      .initList(true)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (shops) => {
          this.list$.set(shops ?? []);
          if (this.autoSelect && shops.length === 1) {
            this.select(shops[0]);
          }
        },
      });
  }
}
