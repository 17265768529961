<nz-spin [nzSpinning]="isLoading">
  <!-- <div class="flex flex-col space-y-5">
    <ng-container *ngFor="let method of list; trackBy: trackByIndex">
      <div
        (click)="select(method)"
        class="py-5 mx-5 border rounded-lg group bg-primary hover:bg-secondary hover:text-white hover:cursor-pointer duration-200 transition-all"
      >
        <div class="text-center font-semibold group">
          {{ "payment.method." + method.methodCode | translate }}
          <ng-container *ngIf="!method.livemode">
            <nz-tag [nzColor]="'#f50'">
              <i nz-icon nzType="setting"></i> Test
            </nz-tag>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div> -->

  <div class="grid grid-cols-2">
    <ng-container *ngFor="let method of list; trackBy: trackByIndex">
      <div
        (click)="select(method)"
        class="py-5 mx-5 my-3 border border-dashed border-gray-600 rounded-lg group hover:text-white hover:bg-primary hover:cursor-pointer duration-200 transition-all"
      >
        <shop-payment-method-item [method]="method"></shop-payment-method-item>
      </div>
    </ng-container>
  </div>
</nz-spin>
