<ng-template #loadingTpl>
  <loading-indicator></loading-indicator>
</ng-template>

<ng-container *ngIf="!isLoading(); else loadingTpl">
  <ng-container *ngIf="shop && schedules">
    <!-- title -->
    <div class="font-bold text-primary text-base">
      {{ "service.schedule.title" | translate }}
    </div>

    <nz-tabset nzType="line" nzSize="small">
      <!-- Pickup -->
      <nz-tab
        [nzTitle]="'service.pickup.title' | translate"
        *ngIf="shop.services.pickup"
      >
        <ng-container *ngIf="!shop.services.pickup">
          <ng-container *ngTemplateOutlet="noPickupService"></ng-container>
        </ng-container>

        <ng-container *ngIf="shop.services.pickup">
          <div
            class="flex flex-col space-y-5 md:flex-row md:justify-start md:space-y-0 md:space-x-20"
          >
            <!-- pickup left -->
            <div class="flex flex-col space-y-3">
              <ng-container
                *ngFor="let item of schedules.services.pickup | scheduleByDay"
              >
                <div>
                  <shop-schedule-item [data]="item"></shop-schedule-item>
                </div>
              </ng-container>
            </div>

            <!-- pickup right -->
            <div>
              <div class="text-primary font-semibold">
                {{ "service.day.exception" | translate }}
              </div>
              <ng-container
                *ngFor="let item of schedules.services.pickup.exceptions"
              >
                <shop-schedule-exception-item
                  [data]="item"
                ></shop-schedule-exception-item>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </nz-tab>

      <!-- Delivery -->
      <nz-tab
        [nzTitle]="'service.delivery.title' | translate"
        *ngIf="shop.services.delivery"
      >
        <ng-container *ngIf="!shop.services.delivery">
          <ng-container *ngTemplateOutlet="noDeliveryService"></ng-container>
        </ng-container>

        <ng-container *ngIf="shop.services.delivery">
          <div
            class="flex flex-col space-y-5 md:flex-row md:justify-start md:space-y-0 md:space-x-20"
          >
            <!-- Delivery Left -->
            <div class="flex flex-col space-y-3">
              <ng-container
                *ngFor="let item of schedules.services.delivery | scheduleByDay"
              >
                <div>
                  <shop-schedule-item [data]="item"></shop-schedule-item>
                </div>
              </ng-container>
            </div>

            <!-- Delivery Right -->
            <div>
              <div class="text-primary font-semibold">
                {{ "service.day.exception" | translate }}
              </div>
              <ng-container
                *ngFor="let item of schedules.services.delivery.exceptions"
              >
                <shop-schedule-exception-item
                  [data]="item"
                ></shop-schedule-exception-item>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </nz-tab>

      <!-- Eat In -->
      <nz-tab
        [nzTitle]="'service.eat_in.title' | translate"
        *ngIf="shop.services.eatIn"
      >
        <ng-container *ngIf="!shop.services.eatIn">
          <ng-container *ngTemplateOutlet="noPickupService"></ng-container>
        </ng-container>
        <ng-container *ngIf="shop.services.eatIn">
          <div
            class="flex flex-col space-y-5 md:flex-row md:justify-start md:space-y-0 md:space-x-20"
          >
            <!-- Eat In Left -->
            <div class="flex flex-col space-y-3">
              <ng-container
                *ngFor="let item of schedules.services.eatIn | scheduleByDay"
              >
                <div>
                  <shop-schedule-item [data]="item"></shop-schedule-item>
                </div>
              </ng-container>
            </div>

            <!-- Eat In Right -->
            <div>
              <div class="text-primary font-semibold">
                {{ "service.day.exception" | translate }}
              </div>
              <ng-container
                *ngFor="let item of schedules.services.eatIn.exceptions"
              >
                <shop-schedule-exception-item
                  [data]="item"
                ></shop-schedule-exception-item>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </ng-container>
</ng-container>

<ng-template #noDeliveryService>
  <nz-alert
    nzBanner
    [nzDescription]="'service.delivery.unavailable' | translate"
  ></nz-alert>
</ng-template>

<ng-template #noPickupService>
  <nz-alert
    nzBanner
    [nzDescription]="'service.delivery.unavailable' | translate"
  ></nz-alert>
</ng-template>
