<ng-container *ngIf="data">
  <div class="flex flex-row justify-start space-x-5">
    <div class="font-semibold capitalize">
      {{ "service.day." + data.dayOfWeek | translate }}
    </div>
    <div>
      <ng-container *ngIf="!data.isClosed; else outOfService">
        <div class="divide-x divide-dotted divide-red-500">
          <ng-container *ngFor="let timetable of data.schedules">
            <span class="px-2">
              {{ timetable | scheduleTimetable }}
            </span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #outOfService>
  <span class="text-primary">
    {{ "service.status.out_of_service" | translate }}
  </span>
</ng-template>
