import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { IShopContact } from "@app/api/shop/interfaces";

@Component({
  selector: "shop-contact",
  templateUrl: "./shop-contact.component.html",
  styleUrls: ["./shop-contact.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopContactComponent implements OnInit {
  @Input() contacts: IShopContact[] = [];
  constructor() {}

  ngOnInit(): void {}
}
