import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'hasControl',
  pure: false
})
export class HasControlPipe implements PipeTransform {

  transform(form: FormGroup, formControlName: string): boolean {
    if (!form) {
      return false;
    }

    if (!form.get(formControlName)) {
      return false;
    } else {
      return true;
    }
  }

}
