<div class="flex flex-row space-x-5 text-gray-400" *ngIf="zone">
  <ng-container *ngIf="zone.deliverableAmount">
    <div>
      <span>
        {{ "service.delivery.zone.selector.deliverable_amount" | translate }}
      </span>
      <span class="block">
        {{ zone.deliverableAmount | currency : currency }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="zone.deliveryFee">
    <div>
      <span>
        {{ "service.delivery.zone.selector.delivery_fee" | translate }}
      </span>

      <span class="block">
        {{ zone.deliveryFee | currency : currency }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="!zone.deliveryFee">
    <div>
      <span>{{
        "service.delivery.zone.selector.delivery_free" | translate
      }}</span>

      <span class="block">
        <i class="bi bi-heart-fill text-red-500"></i>
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="zone.deliverableFreeAmount">
    <div>
      <span>
        {{ "service.delivery.zone.selector.delivery_free_from" | translate }}
      </span>
      <span class="block">
        {{ zone.deliverableFreeAmount | currency : currency }}
      </span>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="zone.deliverySurcharge">
                    <span>
                      Extra delivery fee
                      {{ zone.deliverySurcharge | currency :currency }}
                    </span>
                  </ng-container> -->
</div>
