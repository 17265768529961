<ng-container *ngFor="let contact of contacts">
  <div>
    <ng-container *ngIf="contact.typeCode === 'TEL'">
      <ng-template *ngTemplateOutlet="tel; context: contact"></ng-template>
    </ng-container>
    <ng-container *ngIf="contact.typeCode === 'EMAIL'">
      <ng-template *ngTemplateOutlet="email; context: contact"></ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #tel let-value="value">
  <i class="bi bi-telephone-fill mr-1"></i>
  <a href="tel:0{{ value }}">0{{ value }}</a>
</ng-template>

<ng-template #email let-value="value">
  <i class="bi bi-envelope-fill mr-1"></i> {{ value }}
</ng-template>
