<ng-container *ngIf="loading">
  <div class="flex flex-col w-full mx-auto">
    <div class="mx-auto">
      <img nz-image [nzSrc]="placeholder" class="max-w-[150px]" />
    </div>
    <div class="text-center font-semibold">
      <span>
        {{ "order.request.loading" | translate }}
      </span>
    </div>
  </div>
</ng-container>
