import { Pipe, PipeTransform } from "@angular/core";
import { IServiceScheduleItem } from "@app/api/shop/interfaces";

@Pipe({
  name: "scheduleTimetable",
})
export class ScheduleTimetablePipe implements PipeTransform {
  transform(value: IServiceScheduleItem, ...args: unknown[]): string {
    let timetable: string[] = [];

    let from = value?.timeFrom ?? null;
    if (from) {
      from = from.split(":").reverse().splice(1).reverse().join(":");
      timetable.push(from);
    }

    let to = value?.timeTo ?? null;
    if (to) {
      to = to.split(":").reverse().splice(1).reverse().join(":");
      timetable.push(to);
    }

    return timetable.join(" - ");
  }
}
