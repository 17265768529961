import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable } from "rxjs";
import { HttpHelperService } from "@app/helpers/http";
import {
  IHttpResponse,
  IHttpResponseError,
} from "@app/helpers/http/interfaces";
import { environment } from "@environments/environment";
import { INotice } from "./interfaces";
import { map, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";

@Injectable({
  providedIn: "root",
})
export class NotificationApiService {
  coreEndPoint = environment.coreEndPoint;

  #list = new BehaviorSubject<INotice[]>([]);
  list$ = this.#list.asObservable();
  #count = new BehaviorSubject<number>(0);
  count$ = this.#count.asObservable();

  constructor(
    private http: HttpHelperService,
    private translate: TranslateService,
    private modalService: NzModalService
  ) {}

  set list(data: INotice[]) {
    this.#list.next(data);
    this.#count.next(data.length);
  }

  initList(fromServer = false): Observable<INotice[]> {
    const method = this.getListFromServer<{
      count: number;
      notifications: INotice[];
    }>();

    return method.pipe(
      take(1),
      map((res) => {
        const { data } = res;
        const list = data?.notifications ?? [];
        this.list = list;
        return list;
      })
    );
  }

  getListFromServer<T>(): Observable<IHttpResponse<T>> {
    const path = `${this.coreEndPoint}/api/notifications`;

    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  errorMessage(error: IHttpResponseError): void {
    const { name, code } = error;
    const problems = error.problems ?? [];
    const title = this.translate.instant("message.error." + name + "." + code);
    const message = problems.join("<br />");
    this.modalService.warning({
      nzTitle: title,
      nzContent: `<p class="font-bold">${message}</p>`,
      nzCentered: true,
      nzClosable: false,
    });
  }
}
