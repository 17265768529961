import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'isRequired',
  pure: false
})
export class IsRequiredPipe implements PipeTransform {

  transform(group: FormGroup, ...args: string[]): boolean {
    const controlName = args[0];

    const control = group.get(controlName);
    if (!control || !control?.validator) {
      return false;
    }

    const validator = control.validator(({} as AbstractControl));
    return validator && validator['required'] ? true : false;
  }

}
