import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShopSelectorComponent } from "./shop-selector/shop-selector.component";
import { NzCardModule } from "ng-zorro-antd/card";
import { TranslateModule } from "@ngx-translate/core";
import { ShopLivemodeComponent } from "./shop-livemode/shop-livemode.component";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ShopServiceComponent } from "./shop-service/shop-service.component";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { ShopContactComponent } from "./shop-contact/shop-contact.component";
import { ShopPreviewComponent } from "./shop-preview/shop-preview.component";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { ShopPaymentMethodSelectorComponent } from "./shop-payment-method-selector/shop-payment-method-selector.component";
import { ShopScheduleComponent } from "./shop-schedule/shop-schedule.component";
import { DateFnsModule } from "ngx-date-fns";
import { TranslateHelperModule } from "@app/helpers/translate-helper";
import { RouterModule } from "@angular/router";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { LoadingIndicatorComponent } from "../standalone";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { ScheduleByDayPipe } from "./pipes/schedule-by-day/schedule-by-day.pipe";
import { ShopScheduleItemComponent } from "./shop-schedule-item/shop-schedule-item.component";
import { ScheduleTimetablePipe } from "./pipes/schedule-timetable/schedule-timetable.pipe";
import { ShopScheduleExceptionItemComponent } from "./shop-schedule-exception-item/shop-schedule-exception-item.component";
import { ShopPaymentMethodItemComponent } from "./shop-payment-method-item/shop-payment-method-item.component";
import { ShopDeliveryZoneSelectorComponent } from "./shop-delivery-zone-selector/shop-delivery-zone-selector.component";
import { ShopDeliveryConditionComponent } from "./shop-delivery-condition/shop-delivery-condition.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormHelperModule } from "@app/helpers/form";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";

@NgModule({
  declarations: [
    ShopSelectorComponent,
    ShopLivemodeComponent,
    ShopServiceComponent,
    ShopContactComponent,
    ShopPreviewComponent,
    ShopPaymentMethodSelectorComponent,
    ShopScheduleComponent,
    ShopScheduleItemComponent,
    ShopScheduleExceptionItemComponent,
    ScheduleByDayPipe,
    ScheduleTimetablePipe,
    ShopPaymentMethodItemComponent,
    ShopDeliveryZoneSelectorComponent,
    ShopDeliveryConditionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormHelperModule,
    TranslateModule.forChild(),

    DateFnsModule,
    TranslateHelperModule,
    LoadingIndicatorComponent,

    NzIconModule,
    NzCardModule,
    NzTagModule,
    NzDividerModule,
    NzSpinModule,
    NzTabsModule,
    NzAlertModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
  ],
  exports: [
    ShopSelectorComponent,
    ShopLivemodeComponent,
    ShopServiceComponent,
    ShopContactComponent,
    ShopPreviewComponent,
    ShopPaymentMethodSelectorComponent,
    ShopScheduleComponent,
    ShopScheduleItemComponent,
    ShopScheduleExceptionItemComponent,
    ScheduleByDayPipe,
    ScheduleTimetablePipe,
    ShopPaymentMethodItemComponent,
    ShopDeliveryZoneSelectorComponent,
    ShopDeliveryConditionComponent,
  ],
})
export class ShopComponentModule {}
