import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { PATTERNS } from '../../pattern';
import { Pattern, PatternCode } from '../../pattern/interfaces';

@Pipe({
  name: 'formError',
  pure: false
})
export class FormErrorPipe implements PipeTransform {

  transform(group: FormGroup, ...args: (string | ValidationErrors)[]): string {

    const controlName = args[0] as string;
    const messages = args[1] as ValidationErrors ?? null;
    const control = group.get(controlName);
    if (!control) {
      return 'Unknown error';
    }

    const errors = control.errors;

    if (!errors) {
      return '';
    }

    for (const i in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, i)) {
        const error = errors[i];
        switch (i) {
          case 'email':
            return messages && messages['email'] ? messages['email'] : 'Please use email format, eg: abc@gmail.com';
          case 'max':
            return messages && messages['max'] ? messages['max'] : 'Max. value: ' + error.max;
          case 'min':
            return messages && messages['min'] ? messages['min'] : 'Min. value ' + error.min;
          case 'required':
            return messages && messages['required'] ? messages['required'] : 'required';
          case 'pattern':
            let pattern: Pattern | null = null;

            for (const p in PATTERNS) {
              if (Object.prototype.hasOwnProperty.call(PATTERNS, p)) {
                const code = p as PatternCode;
                const item: Pattern = PATTERNS[code];
                if (item.pattern === error.requiredPattern.replace('^', '') || item.pattern === error.requiredPattern) {
                  pattern = item;
                }
              }
            }

            return messages && messages['pattern'] ? messages['pattern'] : pattern ? pattern.label : ` Text/format error: ${error.requiredPattern}`;
          case 'maxlength':
            return messages && messages['maxLength']
              ? messages['maxLength']
              : `Length cannot be more than ${error.requiredLength}, current length: ${error.actualLength}`;
          case 'minlength':
            return messages && messages['minLength']
              ? messages['minLength']
              : `Length cannot be less than ${error.requiredLength}, current length: ${error.actualLength}`;
          case 'passconf':
            return messages && messages['passconf'] ? messages['passconf'] : 'Password confirmation does not match the login password';
          case 'occupied':
            return messages && messages['occupied'] ? messages['occupied'] : ' Existed';
          default:
            return '';
        }

      }
    }


    return '';
  }

}
