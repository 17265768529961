import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasControlPipe, IsRequiredPipe, FormGroupPipe, FormArrayPipe, FormErrorPipe, FormValuePipe } from './pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SortTagsPipe } from './pipes/sort-tags/sort-tags.pipe';
import { HasValuePipe } from './pipes/has-value/has-value.pipe';



@NgModule({
  declarations: [
    HasControlPipe,
    IsRequiredPipe,
    FormGroupPipe,
    FormArrayPipe,
    FormErrorPipe,
    FormValuePipe,
    SortTagsPipe,
    HasValuePipe,
  ],
  exports: [
    HasControlPipe,
    IsRequiredPipe,
    FormGroupPipe,
    FormArrayPipe,
    FormErrorPipe,
    FormValuePipe,
    SortTagsPipe,
    HasValuePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Dependencies NgZorro
    NzFormModule,
    NzSelectModule,
    NzRadioModule,
    NzIconModule,
    NzToolTipModule
  ]
})
export class FormHelperModule { }
