import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Pipe({
  name: 'hasValue',
  pure: false
})
export class HasValuePipe implements PipeTransform {

  transform(value: FormGroup | FormArray | FormControl, ...args: unknown[]): boolean {
    const [f1] = args;
    const key = f1 as string;
    const form = value?.get(key);
    const formValue = form?.value ?? null;

    if (formValue !== null) {
      return true;
    }

    return false;
  }
}

