import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IMenu } from "@app/api/menu/interfaces";
import { IShopDeliveryZone } from "@app/api/shop/interfaces";
import { Currency, CurrencyCode } from "@app/common/interfaces";

@Component({
  selector: "shop-delivery-condition",
  templateUrl: "./shop-delivery-condition.component.html",
  styleUrls: ["./shop-delivery-condition.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopDeliveryConditionComponent {
  @Input({ required: true }) zone: IShopDeliveryZone | null = null;
  @Input({ required: false }) currency: Currency | undefined = "EUR";
}
