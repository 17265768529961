import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { IServiceSchedule } from "@app/api/shop/interfaces";

@Component({
  selector: "shop-schedule-item",
  templateUrl: "./shop-schedule-item.component.html",
  styleUrls: ["./shop-schedule-item.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopScheduleItemComponent implements OnInit {
  @Input() data: IServiceSchedule | null = null;

  constructor() {}

  ngOnInit(): void {}
}
