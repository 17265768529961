<div
  class="flex flex-col justify-center w-full max-w-xl m-auto space-y-4"
  [ngClass]="centered ? 'h-full' : ''"
>
  <ng-container *ngIf="showLogo">
    <div class="text-center mx-auto">
      <img src="images/logo.png" alt="" class="h-16" />
    </div>
  </ng-container>

  <div class="">
    <p
      class="text-center py-2 text-primary font-semibold text-base md:text-2xl"
    >
      {{ "shop.selector.title" | translate }}
    </p>
    <nz-spin
      [nzTip]="'shop.selector.loading' | translate"
      [nzSpinning]="loading$()"
    >
      <div class="flex flex-col space-y-5 w-full">
        <ng-container *ngFor="let shop of list$(); trackBy: trackByIndex">
          <div class="group hover:cursor-pointer">
            <div
              (click)="select(shop)"
              class="group hover:scale-95 duration-300 ring-1 ring-primary p-2 rounded-md shadow-primary shadow-sm"
            >
              <div class="font-bold">
                {{ shop.name }}
                <shop-livemode [livemode]="shop.livemode"></shop-livemode>
              </div>
              <div>
                {{ shop.geo.address }}, {{ shop.geo.postcode }}
                {{ shop.geo.city }}
              </div>
              <div class="border-t border-primary mt-2 pt-1">
                <shop-service
                  [pickup]="shop.services.pickup"
                  [delivery]="shop.services.delivery"
                  [eatIn]="shop.services.eatIn"
                ></shop-service>
              </div>
              <div>
                {{ shop.description }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </nz-spin>
  </div>
</div>
