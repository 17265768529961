import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { Livemode } from "@app/common/interfaces";

@Component({
  selector: "shop-livemode",
  templateUrl: "./shop-livemode.component.html",
  styleUrls: ["./shop-livemode.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopLivemodeComponent implements OnInit {
  @Input() livemode: Livemode = 1;
  constructor() {}

  ngOnInit(): void {}
}
