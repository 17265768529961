import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { IShop } from "@app/api/shop/interfaces";

@Component({
  selector: "shop-preview",
  templateUrl: "./shop-preview.component.html",
  styleUrls: ["./shop-preview.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopPreviewComponent implements OnInit {
  @Input() shop: IShop | null = null;
  constructor() {}

  ngOnInit(): void {}
}
