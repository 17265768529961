<div
  class="flex flex-col justify-center w-full max-w-xl m-auto space-y-4"
  [ngClass]="centered ? 'h-full' : ''"
>
  <ng-container *ngIf="showLogo">
    <div class="text-center mx-auto">
      <img src="images/logo.png" alt="" class="w-32 md:w-[100px]" />
    </div>
  </ng-container>

  <div class="">
    <p
      class="text-center py-2 text-primary font-semibold text-base md:text-2xl"
    >
      {{ "service.delivery.zone.selector.title" | translate }}
    </p>

    <form
      nz-form
      [formGroup]="validateForm"
      nzLayout="horizontal"
      (ngSubmit)="onSubmit()"
    >
      <nz-form-item>
        <nz-form-control [nzErrorTip]="validateForm | formError : 'keywords'">
          <nz-input-group
            nzSearch
            [nzAddOnAfter]="suffixIconButton"
            [nzSuffix]="suffixIconClear"
          >
            <input
              type="text"
              nz-input
              formControlName="keywords"
              [placeholder]="
                'service.delivery.zone.selector.search_placeholder' | translate
              "
            />
          </nz-input-group>
          <ng-template #suffixIconClear>
            <ng-container *ngIf="validateForm | hasValue : 'keywords'">
              <span
                nz-icon
                nzType="close-circle"
                nzTheme="outline"
                (click)="clear()"
              ></span>
            </ng-container>
          </ng-template>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzType="primary"
              [nzLoading]="loading$()"
              nzSearch
            >
              <span nz-icon nzType="search"></span>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>

    <nz-spin
      [nzTip]="'service.delivery.zone.selector.loading' | translate"
      [nzSpinning]="loading$()"
    >
      <div class="flex flex-col space-y-5 w-full">
        <ng-container *ngIf="list$ | async as list">
          <ng-container *ngIf="!loading$() && !list.length">
            <p class="text-secondary">
              {{ "service.delivery.zone.selector.empty" | translate }}
            </p>
          </ng-container>
          <ng-container *ngFor="let zone of list; trackBy: trackByIndex">
            <div class="group hover:cursor-pointer">
              <div
                (click)="select(zone)"
                class="group hover:scale-95 duration-300 ring-1 ring-primary p-2 rounded-md shadow-black shadow-md"
              >
                <div class="text-primary">
                  <div class="font-bold">
                    {{ zone.city }}
                  </div>
                  <div>
                    {{ "service.delivery.zone.selector.postcode" | translate }}:
                    {{ zone.postcode }}
                  </div>
                </div>
                <div class="border-t border-primary border-dashed mt-2 pt-1">
                  <shop-delivery-condition
                    [currency]="menu?.currency"
                    [zone]="zone"
                  ></shop-delivery-condition>
                </div>
                <div>
                  {{ zone.description }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </nz-spin>
  </div>
</div>
