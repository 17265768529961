import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, map, of, tap } from "rxjs";
import { IMenu } from "@app/api/menu/interfaces";
import { NotificationApiService } from "@app/api/notification";
import { OrderApiService } from "@app/api/order";
import { IOrderService } from "@app/api/order/interfaces";
import { ShopApiService } from "@app/api/shop";
import {
  IShop,
  IShopDeliveryZone,
  ServiceType,
} from "@app/api/shop/interfaces";
import { TrackByIndexFn } from "@app/common/functions";
import { FormHelperService } from "@app/helpers/form";

@Component({
  selector: "app-shop-delivery-zone-selector",
  templateUrl: "./shop-delivery-zone-selector.component.html",
  styleUrls: ["./shop-delivery-zone-selector.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopDeliveryZoneSelectorComponent implements OnInit {
  @Input() showLogo = false;
  @Input() centered = false;
  @Input() autoSelect = false;
  @Input() isCartPage = false;
  @Input() syncList = false;
  @Input({ required: true }) service: IOrderService | null = null;
  @Input() event = new EventEmitter<boolean>();
  @Input() menu: IMenu | null = null;
  readonly shop$ = this.shopService.shop$;
  loading$ = this.shopService.loading$;
  trackByIndex = TrackByIndexFn;
  list$: Observable<IShopDeliveryZone[]> = of([]);

  validateForm = new UntypedFormGroup({
    keywords: this.fb.control(null, [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  private destroyRef = inject(DestroyRef);
  constructor(
    private fb: FormBuilder,
    private fv: FormHelperService,
    private shopService: ShopApiService,
    private orderService: OrderApiService,
    private noticeService: NotificationApiService
  ) {}

  ngOnInit(): void {
    this.sync();
  }

  close(state: boolean) {
    this.event.emit(state);
  }

  select(zone: IShopDeliveryZone) {
    this.loading$.set(true);
    this.orderService
      .updatePresetServiceType({
        type: (this.service?.type as ServiceType) ?? 2,
        zoneId: zone.id,
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loading$.set(false))
      )
      .subscribe({
        next: (res) => {
          const { error, success } = res;
          if (error) {
            this.noticeService.errorMessage(error);
          }

          if (success) {
            this.close(true);
          }
        },
      });
  }

  clear(): void {
    this.validateForm.reset();
    this.sync();
  }

  onSubmit(): void {
    if (this.validateForm.invalid) {
      this.fv.markAllAsDirty(this.validateForm);
    }

    if (this.validateForm.valid) {
      const body = this.validateForm.value;
      this.sync(body);
    }
  }

  private sync(body: any = {}): void {
    this.list$ = this.shopService
      .getDeliveryZones<{
        zones: IShopDeliveryZone[];
        menu: IMenu;
        shop: Pick<IShop, "name" | "id">;
      }>(this.shop$()?.id ?? 0, body)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((res) => {
          const zones = (res.data.zones as IShopDeliveryZone[]) ?? [];
          this.menu = res.data.menu;
          return zones;
        })
      );
  }
}
