<ng-container *ngIf="method">
  <div class="text-center font-semibold group">
    <div
      class="payment_logo payment_cash"
      *ngIf="method.methodCode === 'cash'"
    ></div>
    <div
      class="payment_logo payment_visa"
      *ngIf="method.methodCode === 'visa'"
    ></div>
    <div
      class="payment_logo payment_visa"
      *ngIf="method.methodCode === 'visacard'"
    ></div>
    <div
      class="payment_logo payment_mastercard"
      *ngIf="method.methodCode === 'mastercard'"
    ></div>
    <div
      class="payment_logo payment_bancontact"
      *ngIf="method.methodCode === 'bancontact'"
    ></div>
    <div
      class="payment_logo payment_card_terminal"
      *ngIf="method.methodCode === 'card_terminal'"
    ></div>
    {{ "payment.method." + method.methodCode | translate }}

    <ng-container *ngIf="!method.livemode">
      <nz-tag [nzColor]="'#f50'">
        <i nz-icon nzType="setting"></i> Test
      </nz-tag>
    </ng-container>
  </div>
</ng-container>
