<div class="flex flex-row space-x-5">
  <ng-container *ngIf="eatIn">
    <span class="text-md text-lime-800">
      <i class="bi bi-emoji-smile-fill"></i>
      {{ "service.eat_in.title" | translate }}
    </span>
  </ng-container>

  <!-- <ng-container *ngIf="!eatIn">
    <span class="text-md text-slate-400">
      <i class="bi bi-emoji-dizzy"></i> {{ "service.eat_in.title" | translate }}
    </span>
  </ng-container> -->

  <ng-container *ngIf="pickup">
    <span class="text-md text-lime-600">
      <i class="bi bi-bag-check-fill"></i>
      {{ "service.pickup.title" | translate }}
    </span>
  </ng-container>

  <!-- <ng-container *ngIf="!pickup">
    <span class="text-md text-slate-400">
      <i class="bi bi-bag-x-fill"></i> {{ "service.pickup.title" | translate }}
    </span>
  </ng-container> -->

  <ng-container *ngIf="delivery">
    <span class="text-md text-lime-600">
      <i class="bi bi-bag-check-fill"></i>
      {{ "service.delivery.title" | translate }}
    </span>
  </ng-container>

  <!-- <ng-container *ngIf="!delivery">
    <span class="text-md text-slate-400">
      <i class="bi bi-x-circle-fill"></i>
      {{ "service.delivery.title" | translate }}
    </span>
  </ng-container> -->
</div>
