import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'formGroup',
  pure: true
})
export class FormGroupPipe implements PipeTransform {

  transform(value: FormGroup, ...args: string[]): FormGroup | null {
    const [f1] = args;

    const form = value && value.get(f1) as FormGroup;

    return form;
  }

}
