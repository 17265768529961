import { PatternCode, Pattern } from './interfaces';


export const PATTERNS: Record<PatternCode, Pattern> = {
    alphabet_number: {
        pattern: `[a-zA-Z àâçéèêëîïôûùüÿñæœōćšáýÆ \\d @#-/.,:;()\\x21-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7B-\\x7F]*$`,
        label: '请仅使用 英文字母及英文标点符号 & 数字, 包括(àâçéèêëîïôûùüÿñæœōćšáýÆ@#-/.,:;())',
        code: PatternCode.alphabet_number
    },
    chinese: {
        pattern: `[\\u4e00-\\u9fa5\\s+]+$`,
        label: '请使用中文填写',
        code: PatternCode.chinese
    },
    postcode_ca: {
        pattern: `[\\s]*[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\\d{1}[a-zA-Z]{1}[-|\\s]*\\d{1}[a-zA-Z]{1}\\d{1}[\\s]*$`,
        label: '请使用正确规格, 例: a1b 2c3',
        code: PatternCode.postcode_ca
    },
    postcode_us: {
        pattern: `[\\s]*\\d{5}[ ]*$|^[\\s]*\\d{5}[-|\\s]*(\\d{4})[\\s]*$`,
        label: '请使用正确规格: 5位或9位数字',
        code: PatternCode.postcode_us
    },
    postcode_cn: {
        pattern: `[0-9]{6}$`,
        label: '请使用正确规格: 6位数字 例: 100000',
        code: PatternCode.postcode_cn
    },
    phone_be: {
        pattern: `[\\s\\d+-]*$`,
        label: 'For example: 0484 123 456',
        code: PatternCode.phone_be
    },
    phone_ca: {
        pattern: `\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$`,
        label: '请使用如下格式: 999-999-9999, 9999999999, (999) 999-9999',
        code: PatternCode.phone_ca
    },
    phone_us: {
        pattern: `\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$`,
        label: '请使用如下格式: 999-999-9999, 9999999999, (999) 999-9999',
        code: PatternCode.phone_us
    },
    phone_cn: {
        pattern: `((\\+86-)|(86-))?[1]([3-9])[0-9]{9}$`,
        label: '请使用如下格式: 135 xxxx xxxx',
        code: PatternCode.phone_cn
    },
    phone_uni: {
        pattern: `[\\d @#-+/.,:;()]*$`,
        label: '所在地区电话',
        code: PatternCode.phone_uni
    },
    item_name_uni: {
        pattern: `[a-zA-Z àâçéèêëîïôûùüÿñæœōćšáýÆ \\d @#-/.,:;()\\x21-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7B-\\x7F]*$`,
        label: '请仅使用 英文字母及英文标点符号 & 数字, 包括(àâçéèêëîïôûùüÿñæœōćšáýÆ@#-/.,:;())',
        code: PatternCode.item_name_uni
    },
    item_name_jiayun: {
        pattern: '([_a-zA-Z0-9\\s]{1,35})(\\|{1})(\\s*)([\\u4e00-\\u9fa5_a-zA-Z0-9\\s]{1,35})(\\s*)(\\|{1})(\\s*)([\\u4e00-\\u9fa5_a-zA-Z0-9\\s]{2,5})(\\s*)$',
        label: '请使用固定格式, 例: Sports Shoes | 运动鞋 | 1双',
        code: PatternCode.item_name_jiayun
    },
    email: {
        pattern: `^[-a-zA-Z0-9~!$%^&*_=+}{\\'?]+(\\.[-a-zA-Z0-9~!$%^&*_=+}{\\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\\.[-a-zA-Z0-9_]+)*\\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}))(:[0-9]{1,5})?$`,
        label: 'Please use email format, eg: abc@gmail.com',
        code: PatternCode.email
    },
    password: {
        pattern: `^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*_+=./?]{8,}$`,
        label: '密码至少8个字母，至少一个大写字母，一个小写字母，一个数字，可选字符: !@#$%^&*_+=./?',
        code: PatternCode.password
    },
    no_numeric: {
        pattern: `^([^0-9]*)$`,
        label: '不能使用数字',
        code: PatternCode.no_numeric
    }
};
