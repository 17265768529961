import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { IServiceScheduleItem } from "@app/api/shop/interfaces";

@Component({
  selector: "shop-schedule-exception-item",
  templateUrl: "./shop-schedule-exception-item.component.html",
  styleUrls: ["./shop-schedule-exception-item.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopScheduleExceptionItemComponent implements OnInit {
  @Input() data: IServiceScheduleItem | null = null;

  constructor() {}

  ngOnInit(): void {}
}
