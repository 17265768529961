import { Pipe, PipeTransform } from "@angular/core";
import {
  IServiceSchedule,
  IServiceScheduleItem,
  ShopServiceSchedule,
} from "@app/api/shop/interfaces";
import { DayOfWeek } from "@app/common/interfaces";

@Pipe({
  name: "scheduleByDay",
})
export class ScheduleByDayPipe implements PipeTransform {
  transform(
    value: ShopServiceSchedule,
    ...args: unknown[]
  ): IServiceSchedule[] {
    const list: IServiceSchedule[] = [];
    if (!value) {
      return list;
    }

    for (const i in value) {
      if (Object.prototype.hasOwnProperty.call(value, i)) {
        const key = i as unknown as DayOfWeek;
        const element = value[key] as IServiceSchedule;
        if (i !== "exceptions") {
          list.push(element);
        }
      }
    }

    return list;
  }
}
