import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { OrderApiService } from "@app/api/order/order.service";
import { IPaymentMethod } from "@app/api/payment/interfaces";
import { ShopAlertService, ShopApiService } from "@app/api/shop";
import { TrackByIndexFn } from "@app/common/functions";

@Component({
  selector: "shop-payment-method-selector",
  templateUrl: "./shop-payment-method-selector.component.html",
  styleUrls: ["./shop-payment-method-selector.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopPaymentMethodSelectorComponent implements OnInit, OnDestroy {
  @Input() autoSelect = false;
  @Output() methodChange = new EventEmitter<IPaymentMethod | null>();

  list: IPaymentMethod[] = [];
  trackByIndex = TrackByIndexFn;

  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.detection.detectChanges();
  }

  private destroy$ = new Subject<void>();
  constructor(
    private shopService: ShopApiService,
    private orderService: OrderApiService,
    private detection: ChangeDetectorRef,
    private shopAlertService: ShopAlertService
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private getList(): void {
    this.isLoading = true;
    this.shopService
      .getPaymentMethods()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          const { data } = res;
          this.list = data ?? [];
          this.isLoading = false;
        },
      });
  }

  close(method: IPaymentMethod) {
    this.methodChange.emit(method);
  }

  select(method: IPaymentMethod) {
    this.isLoading = true;
    this.orderService
      .updatePresetPayment({ method })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          const { error, success } = res;
          if (error) {
            this.shopAlertService.errorMessage(error);
          }

          if (success) {
            this.close(method);
          }
        },
      });
  }
}
