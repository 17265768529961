import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ShopApiService } from "@app/api/shop";
import { IShop, IShopServiceSchedules } from "@app/api/shop/interfaces";

@Component({
  selector: "shop-schedule",
  templateUrl: "./shop-schedule.component.html",
  styleUrls: ["./shop-schedule.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopScheduleComponent implements OnInit {
  @Input() shop: IShop | null = null;
  schedules: IShopServiceSchedules | null = null;

  private destroyRef = inject(DestroyRef);

  isLoading = signal(false);

  constructor(private shopService: ShopApiService) {}

  ngOnInit(): void {
    if (this.shop) {
      this.getSchedule(this.shop.id);
    }
  }

  getSchedule(shopId: number): void {
    this.isLoading.set(true);
    this.shopService
      .getSchedules(shopId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          const { data } = res;
          this.schedules = data ?? null;
          this.isLoading.set(false);
        },
      });
  }
}
