<ng-container *ngIf="shop">
  <div class="font-bold text-primary text-base">
    {{ shop.name }}
    <shop-livemode [livemode]="shop.livemode"></shop-livemode>
  </div>
  <div>
    <i class="bi bi-geo-alt-fill"></i> {{ shop.geo.address }},
    {{ shop.geo.postcode }} {{ shop.geo.city }}
  </div>

  <!-- contact -->
  <div>
    <shop-contact [contacts]="shop.contacts"></shop-contact>
  </div>

  <!-- service -->
  <div class="border-t border-primary border-dashed my-1 py-1">
    <shop-service
      [pickup]="shop.services.pickup"
      [delivery]="shop.services.delivery"
      [eatIn]="shop.services.eatIn"
    ></shop-service>
  </div>
</ng-container>
