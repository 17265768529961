import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NzImageModule } from "ng-zorro-antd/image";
import { thumbPlaceholder } from "@app/api/menu/constants";

@Component({
  selector: "loading-indicator",
  templateUrl: "./loading-indicator.component.html",
  styleUrls: ["./loading-indicator.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, NzImageModule],
})
export class LoadingIndicatorComponent implements OnInit {
  placeholder = thumbPlaceholder;
  @Input() loading: boolean | null = true;
  constructor() {}

  ngOnInit(): void {}
}
